import debounce from "../utils/debounce.js";
import initCustomScrollbar from "../modules/init-custom-scrollbar.js";

const initBlock = async function( block ) {
	const body = $( "body" );
	const menuToggler = block.find( "[data-menu-toggler]" );
	const menu = block.find( "[data-menu]" );
	const menuScrollWrapper = block.find( "[data-scroll-wrapper]" );

	await import( "../plugins/scrollbar.min.js" );

	class Menu {
		constructor() {
			this.$html = $( "html" );
			this.$body = $( "body" );
			this.$header = $( ".section-header" );
			this.init();
		}

		init() {
			if ( !this.$header.length ) {
				return;
			}

			this.$menu = this.$header.find( ".section-header__nav-list" );
			this.$menu
				.find( ".dropdown-arrow" )
				.closest( "li" )
				.removeClass( "menu-active" );
			this.$menu.find( ".sub-menu" ).slideUp( 0 );

			this.menuToggle();
		}

		menuToggle() {
			const debouncedMenuToggle = debounce( function( $target, isActive ) {
				const $parentMenuItem = $target.closest( "li" );
				const $subMenu = $parentMenuItem.find( "> .sub-menu" );

				this.$menu.find( ".menu-item.menu-active" ).each( function() {
					const $item = $( this );
					if ( $item[0] !== $parentMenuItem[0] ) {
						const $submenu = $item.find( "> .sub-menu" );
						$submenu.stop( true, true ).slideUp( 100 );
						$item.removeClass( "menu-active" );
					}
				} );

				if ( $parentMenuItem.hasClass( "menu-active" ) || isActive ) {
					const $childrenSubmenuParent = $subMenu.find( ".menu-item-has-children" );
					const $childrenSubmenu = $childrenSubmenuParent.find( ".sub-menu" );

					$subMenu.slideUp( 100 );
					$parentMenuItem.removeClass( "menu-active" );

					$childrenSubmenu.slideUp( 100 );
					$childrenSubmenuParent.removeClass( "menu-active" );
				} else {
					$subMenu.stop( true, true ).slideDown( 100 );
					$parentMenuItem.addClass( "menu-active" );
				}
			}, 100 );

			this.$menu.find( ".dropdown-arrow" ).on( "click", ( e ) => {
				e.preventDefault();
				if ( window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
				const $this = $( e.currentTarget );
				debouncedMenuToggle.call( this, $this );
			} );

			let $menuItemsWithoutChildren = this.$menu.find( "> .menu-item:not(.menu-item-has-children)" );

			$menuItemsWithoutChildren.on( "mouseenter", ( e ) => {
				if ( !window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
				e.preventDefault();
				block.addClass( "menu-hovered" );
			} );

			$menuItemsWithoutChildren.on( "mouseleave", ( e ) => {
				if ( !window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
				e.preventDefault();
				block.removeClass( "menu-hovered" );
			} );

			let $menuItemHasChildren = this.$menu.find( ".menu-item-has-children" );
			let $menuLink = $menuItemHasChildren.find( "> a" );

			$menuLink.on( "click", ( e ) => {
				if ( $( e.currentTarget ).attr( "href" ) === "#" ) {
					e.preventDefault();
					if ( window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
					const $this = $( e.currentTarget );
					debouncedMenuToggle.call( this, $this );
				}
			} );

			$menuItemHasChildren.on( "mouseenter", ( e ) => {
				if ( !window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
				e.preventDefault();
				const $this = $( e.currentTarget );
				debouncedMenuToggle.call( this, $this, false );
				block.addClass( "menu-hovered" );
			} );

			$menuItemHasChildren.on( "mouseleave", ( e ) => {
				if ( !window.matchMedia( "(min-width: 1280px)" ).matches ) { return; }
				e.preventDefault();
				const $this = $( e.currentTarget );
				debouncedMenuToggle.call( this, $this, true );
				block.removeClass( "menu-hovered" );
			} );
		}
	}

	new Menu();

	menuToggler.on( "click", function() {
		if ( menu.hasClass( "active" ) ) {
			hideMenu();
		} else {
			showMenu();
		}
	} );

	function hideMenu() {
		body.removeClass( "scroll-locked" );
		menuToggler.removeClass( "active" );
		menu.removeClass( "active" );
		block.removeClass( "menu-open" );
	}

	function showMenu() {
		body.addClass( "scroll-locked" );
		menuToggler.addClass( "active" );
		menu.addClass( "active" );
		block.addClass( "menu-open" );
	}

	$( window ).on( "scroll", () => {
		if ( $( window ).scrollTop() < 10 ) {
			block.removeClass( "menu-sticky" );
		} else {
			block.addClass( "menu-sticky" );
		}
	} );

	const menuNav = block.find( "[data-menu-nav]" ).clone( true, true );

	if ( !window.matchMedia( "(min-width: 1280px)" ).matches ) {
		initCustomScrollbar( block );
	}

	window.addEventListener( "resize", () => {
		if ( window.matchMedia( "(min-width: 1280px)" ).matches ) {
			let scrollOuter = $( "<div class=\"scrollbar-outer\"></div>" );
			menuNav.appendTo( scrollOuter );
			menuScrollWrapper.empty().append( scrollOuter );
			hideMenu();
		} else {
			initCustomScrollbar( block );
		}
	} );

};

themeUtils.loadBlock( initBlock, "header", ".section-header" );
