const loadBlock = ( initBlock, blockName, blockSelector ) => {
	if ( window.acf ) {
		window.acf.addAction( "ready", function() {
			if ( window.acf.isGutenberg() ) {
				console.log( blockSelector );
				window.acf.addAction(
					"render_block_preview/type=" + blockName,
					themeUtils.adminInitializeBlock( initBlock, blockSelector )
				);
			}
		} );
	} else {
		$( document ).ready( function() {
			let $blockSections = $( blockSelector );
			if ( $blockSections.length ) {
				$blockSections.each( function() {
					initBlock( $( this ) );
				} );
			} else {
				const observer = new MutationObserver( ( mutations, obs ) => {
					let $blockSections = $( blockSelector );
					if ( $blockSections.length ) {
						$blockSections.each( function() {
							initBlock( $( this ) );
						} );
						obs.disconnect();
					}
				} );
				observer.observe( document.body, { childList: true, subtree: true } );
			}
		} );
	}
};

export default loadBlock;
